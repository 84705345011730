import axios from "../http-common";
import authHeader from "./auth-header";

class MessageDataService {

  //, sellerId, buyerId) {

  getByListing(listingId) {  
    return axios.get(`/messages/listing/${listingId}`);
  }

  getByListingAndBuyer(listingId, buyerProfileId) {  
    return axios.get(`/messages/listing/${listingId}/${buyerProfileId}`);
  }
  // http://localhost:5000/api/messages/listing/12/30 
  
  get(id) {
    return axios.get(`/message/${id}`);
  }

  getByEmail(email) {
    return axios.get(`/messages/email/${email}`);
  }

  getForSellerByProfileId(profileId) {
    return axios.get(`/messages/findForSellerByProfileId/${profileId}`);
  }

  getForBuyerByProfileId(profileId) {
    return axios.get(`/messages/findForBuyerByProfileId/${profileId}`);
  }

  // http://localhost:5000/api/messages/getForSellerByProfileId/30 

  create(data) {
    return axios.post("/messages", data, { headers: authHeader() });
  }

  update(id, data) {
    return axios.put(`/messages/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/messages/${id}`);
  }

}

export default new MessageDataService();
