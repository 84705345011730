import React from "react";
import ListingList from "../Listing/ListingList";

const Home = () => {

  return (
    <div className="container">
      <ListingList/>
    </div>
  );
};

export default Home;
