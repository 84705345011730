import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Route, Routes, useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import ProfileAuthService from "../../services/profile.auth.service";
import Register from "../Profile/Register";
import VerifyEmail from "../Profile/VerifyEmail";
import Login from "../Profile/Login";
import Profile from "../Profile/Profile";
import PasswordReset from "../Profile/PasswordReset";

import MyListings from "../Listing/MyListings";
import MyMessages from "../Message/MyMessages";

import Home from "../Home/Home";
import ListingList from "../Listing/ListingList";
import SiteMap from "../Home/SiteMap";

import ProductSearch from "../ProductSearch/ProductSearch";
import CreateListing from "../Listing/CreateListing";
import Listing from "../Listing/Listing";

import ListingSearch from "../Listing/ListingSearch";

import LocationLookup  from '../App/LocationLookup';

function App() {
  const [currentProfile, setCurrentProfile] = useState(undefined);
  const [searchTitle, setSearchTitle] = useState("");
  const [postcodeImportId, setPostcodeImportId] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Pass-A-Buck';
    const profile = ProfileAuthService.getCurrentProfile();

    if (profile) {
      setCurrentProfile(profile);
    }
  }, []);
  
  const handleLogOut = () => {
    ProfileAuthService.logout();
    window.location.reload();
  };

  const handleChangeSearchTitle = event => {
    setSearchTitle(event.target.value)
  }

  const onFixError = () => {
    // do nothing
  }

  const handleSubmit = event => {
    event.preventDefault();

      // paramTitle/paramImportId 
      // http://localhost:3000/ListingSearch/gary/4483
      navigate('/ListingSearch/'+searchTitle+'/'+postcodeImportId)

    // if (!formData.title) {
    //   // setMessageTitle("Keyword is a required field.")
    //   return;
    // }

    // if (formData.title.length < 4 || formData.title.length > 80) { 
    //   //setMessageTitle("The ... must be between 4 and 40 characters.")
    //   return;
    // }

    // if (!postcodeImportId) {
    //   setMessagePostcodeImportId("Location is a required field.")
    //   return;
    // }

    // setSubmitting(true);
    // getSearchListings(1);
  }


  return (
    <div className="wrapper">

      <Link to={"/"} >
        <h3>PassABuck</h3>
      </Link>
      <nav className="navbar navbar-expand navbar-dark bg-dark justify-content-center">
        <ul className="nav">
          {/* <li className="nav-item">
            <a className="nav-link" href="/">Home</a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link" href="/SiteMap">SiteMap</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/CreateListing">Create Listing</a>
          </li>
          {currentProfile ? (
            <>
              <li className="nav-item">
                <a href="/Profile" className="nav-link nav-link-bold">
                  {currentProfile.firstName}
                </a>
              </li>


              <li className="nav-item">
                <a href="/Listing/MyListings" className="nav-link nav-link-bold">
                  My Listings
                </a>
              </li>

              <li className="nav-item">
                <a href="/Message/MyMessages" className="nav-link nav-link-bold">
                  My Messages
                </a>
              </li>

              <li className="nav-item">
                <span className="nav-link" onClick={handleLogOut}>
                  Logout
                </span>
              </li>
              
            </>
          ) : (
            <>
              <li className="nav-item">
                <a href="/Register" className="nav-link">
                  Register
                </a>
              </li>

              <li className="nav-item">
                <a href="/Login" className="nav-link">
                  Login
                </a>
              </li>
            </>
          )}

          <li className="nav-item">
            <a href="/ListingSearch" className="nav-link">
            Search
            </a>
          </li>

          <li className="nav-item">
            <form onSubmit={handleSubmit}>
                <div style={{'display': `flex`}}>
                  <input 
                    placeholder="What ..."
                    name="title" 
                    type="text" 
                    onChange={handleChangeSearchTitle} 
                    value={searchTitle || ''} 
                    className="form-control border border-dark"
                    style={{width: `120px`, height: `34px`, 'margin': `0px`}}
                  />
                  <LocationLookup 
                    placeholder="Where ..." 
                    name="inputLocation" 
                    importId={postcodeImportId} 
                    onSet={setPostcodeImportId} 
                    onFixError={onFixError} 
                    customWidth="120px" 
                    customHeight="34px"
                  />
                  <button type="submit" className="btn btn-primary btn-block" style={{'font-weight': `700`, 'width': `120px`, 'height': '34px', 'margin-left': `1px`, 'padding-top': `4px`}}>
                      Search
                  </button>
                </div>

            </form>
          </li>
        </ul>

      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/category/:categoryId?/:categoryName?/section/:sectionId?/:sectionName?/subSection/:subSectionId?/:subSectionName?" element={<ListingList />} />
          {/* <Route path="/section/(page)?/:page?/(sort)?/:sort?" component={Section} /> */}
          <Route path="/SiteMap" element={<SiteMap/>} />

          <Route path="/Register" element={<Register />} />
          <Route path="/Profile" element={<Profile/>} />

          <Route path="/Login" element={<Login/>} />
          <Route path="/Logout" element={<Login/>} />

          <Route path="/PasswordReset" element={<PasswordReset/>} />
          <Route path="/PasswordReset/:resetToken" element={<PasswordReset/>} />

          <Route path="/VerifyEmail/:token" element={<VerifyEmail/>} />

          <Route path="/Listing/MyListings" element={<MyListings/>} />
          <Route path="/Message/MyMessages" element={<MyMessages/>} />
          
          <Route path="/CreateListing" element={<CreateListing />} />
          <Route path="/CreateListing/:id" element={<CreateListing />} />
          <Route path="/CreateListing/product/:productId" element={<CreateListing />} />

          <Route path="/listing/:id" element={<Listing />} />
          <Route path="/ListingSearch/:paramTitle?/:paramImportId?" element={<ListingSearch />} />


          <Route path="/productSearch" element={<ProductSearch />} />

        </Routes>
      </div>

    </div>
  );
}

export default App;