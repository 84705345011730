import React, { useState,   useReducer, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileAuthService from "../../services/profile.auth.service";
import ListingDataService from "../../services/ListingService";
import ProductDataService from "../../services/ProductService";
import FileService from "../../services/FileService";
import { categoryData, sectionData, subSectionData, defaultCategoryId, defaultSectionId, defaultSubSectionId } from "../../data/hierarchy";
import Utils from '../../services/utils';
import WordBox from '../App/WordBox';
import LocationLookup  from '../App/LocationLookup';

const sanitizeHtml = require('sanitize-html');
const sanitizeSettings = {
  allowedTags: [ 'b', 'i', 'em', 'strong', 'a' ],
  allowedAttributes: {
    'a': [ 'href' ]
  },
  allowedIframeHostnames: []
}

const formReducer = (state, event) => {
  if(event.reset) {
    return {
      id: null,
      profileId: null,
      title: '',
      description: '',
      price: '',
      isNew: false,
      showMobile: true
    } 
  }
  return {
    ...state,
    [event.name]: event.value
  }
} 
 
const CreateListing = () => {
  const { id, productId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [currentProfile, setCurrentProfile] = useState({});
  const [listingId, setListingId]  = useState(null);
  const [images, setImages]= useState(Array(4).fill(''));
  const [published, setPublished] = useState(false);

  const [retailerName, setRetailerName] = useState("");
  const [retailerPrice, setRetailerPrice] = useState("");
  const [retailerPriceDate, setRetailerPriceDate] = useState("");
  const [messageFileSize, setMessageFileSize] = useState("");

  const [message, setMessage] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageCategory, setMessageCategory] = useState("");
  const [messageSection, setMessageSection] = useState("");

  const [messagePrice, setMessagePrice] = useState("");
  const [messagePostcodeImportId, setMessagePostcodeImportId] = useState("");
  const [messageMobile, setMessageMobile] = useState("");

  // const [categoryId, setCategoryId]= useState(0);
  // const [sectionId, setSectionId]= useState(0);
  // const [subSectionId, setSubSectionId]= useState(0);
  // const [postcodeImportId, setPostcodeImportId] = useState("");

  // const [formData, setFormData] = useReducer(formReducer, {
  //   id: null,
  //   profileId: null,
  //   title: "",
  //   description: "",
  //   price: "",
  //   isNew: false,
  //   showMobile: false
  // });

  const [formData, setFormData] = useReducer(formReducer, {
    id: null,
    profileId: null,
    title: "test title<script>",
    description: "test desc<script>",
    price: "1",
    isNew: true,
    showMobile: false
  });

  const [categoryId, setCategoryId]= useState("1");
  const [sectionId, setSectionId]= useState("1");
  const [subSectionId, setSubSectionId]= useState("0");
  const [postcodeImportId, setPostcodeImportId] = useState("4483");

  const navigate = useNavigate();

  useEffect(() => {
    const profile = ProfileAuthService.getCurrentProfile();
    if (profile) {
      setCurrentProfile(profile);
      setPostcodeImportId(profile.postcodeImportId);
    } else {
      // Navigate({ to: '/ProfileLogin' })
      navigate('/Login?refPage=%2FCreateListing')
    }
  }, []);

  useEffect(() => {
    if (productId) {
      console.log("productId: " + productId)
      getProduct(productId);
    }
  }, [])

  const getProduct = id => {
    ProductDataService.get(id)
      .then(response => {
            const responseData = response.data;
            console.log(response.data);
            setFormData({name: "title", value: responseData.title});
            setCategoryId(defaultCategoryId);
            setSectionId(defaultSectionId);
            setCategoryId(defaultSubSectionId);
            let newDesc = Utils.scrubOutHTML(responseData.description).trim();
            setFormData({name: "description", value: newDesc});
            setFormData({name: "price", value: ""}); 
            setRetailerName(responseData.retailerName);
            setRetailerPrice(responseData.price); 
            setRetailerPriceDate(responseData.updatedAt); 
        })
      .catch(e => {
        console.log(e);
    });
  }

  useEffect(() => {
    if (id) {
      console.log("id: " + id)
      setListingId(id)
      getListing(id)
    }  
  }, [id, listingId]);

  const getListing = id => {
    if (currentProfile) { 
      ListingDataService.getWithSeller(id)
        .then(response => {
          console.log("ListingDataService.getWithSeller[] " + JSON.stringify(response.data[0]))
          const responseData = response.data[0]

          setFormData({name: "profileId", value: currentProfile.id}) ;
          setFormData({name: "title", value: responseData.title});
          setFormData({name: "description", value: responseData.description});
          setFormData({name: "price", value: responseData.price});
          setFormData({name: "isNew", value: responseData.isNew});
          setFormData({name: "showMobile", value: responseData.showMobile});
          setFormData({name: "published", value: responseData.published});

          setCategoryId(responseData.categoryId.toString());
          setSectionId(responseData.sectionId.toString());
          setSubSectionId(responseData.subSectionId.toString());

          setPostcodeImportId(responseData.location);
          setPublished(responseData.published);

          let tempImages = Array(4).fill('');
          tempImages[0] = responseData.image1;
          tempImages[1] = responseData.image2;
          tempImages[2] = responseData.image3;
          tempImages[3] = responseData.image4;
          setImages(tempImages);
        })
        .catch(e => {
          console.log(e);
        });
      }
  };

  const createListing = () => {
    if (currentProfile) {
      let data = {
        id: null, 
        profileId: currentProfile.id,  
        title: formData.title ,
        categoryId: categoryId,
        sectionId: sectionId,
        subSectionId: subSectionId,
        description: formData.description,
        price: formData.price,
        location: postcodeImportId,
        isNew:  formData.isNew,
        showMobile: formData.showMobile,
        published:  published,
        image1: images[0],
        image2: images[1],
        image3: images[2],
        image4: images[3],
      } 

    ListingDataService.create(data)
      .then(response => {
        setSubmitting(false);
        if (response.data.id) { 
          setMessage("Listing Created")
          setListingId(response.data.id)
          setFormData({name: "title", value: sanitizeHtml(formData.title, sanitizeSettings )});
          setFormData({name: "description", value: sanitizeHtml(formData.description, sanitizeSettings )});
        }
      })
      .catch(e => {
          console.log(e);
      });
    } 
  }; 

  const updateListing = (newPublished=false) => {
    console.log("updateListing - newPublished: "+newPublished)
    if (currentProfile) {
      let data = {
        id: listingId,
        profileId: currentProfile.id, 
        title: formData.title,
        categoryId: categoryId,
        sectionId: sectionId,
        subSectionId: subSectionId,
        description: formData.description,
        price: formData.price,
        location: postcodeImportId,
        isNew:  formData.isNew,
        showMobile: formData.showMobile,
        published: newPublished,
        image1: images[0],
        image2: images[1],
        image3: images[2],
        image4: images[3],
      } 
 
    ListingDataService.update(listingId, data) 
      .then(response => {       
        setSubmitting(false);
        // if (publish=published) {
        //   setMessage("Listing Published")
        // } else {
        //   setMessage("Listing Updated")
        // }
        setFormData({name: "title", value: sanitizeHtml(formData.title, sanitizeSettings )});
        setFormData({name: "description", value: sanitizeHtml(formData.description, sanitizeSettings )});
      })
      .catch(e => {
          console.log(e);
      });
    }
  }; 

  const handleChange = event => {
    let newValue = event.target.value
    if (event.target.name === `title`) {  setMessageTitle(""); }

    if (event.target.name === `price`) { 
      newValue = newValue.replace(/[^0-9.,]/g, '') 
      setMessagePrice("");
    }

    setFormData({
      name: event.target.name,
      value: newValue,
    });
  }

  const handleCheckBoxChange = event => {
    setFormData({
      name: event.target.name,
      value: (event.target.checked===true) ? true : false,
    });
  }

  const inputFile = useRef(null);

  const handleFileInput = (e) => {
    const formDataImage1 = new FormData(); 
    formDataImage1.append('my-image-file', e.target.files[0], e.target.files[0].name);
    if (e.target.files[0].size > FileService.maxUploadFileSize ) { 
      // Show message as deemed necessary!
      setMessageFileSize("File is too large")
    } else {
      setMessageFileSize("")
      FileService.uploadImage(formDataImage1)
        .then(res => {
          if (res.status===200) {
            // *** needs 
            const tempImages = images.slice();
            if (tempImages[0].length===0) { 
              tempImages[0] = res.data.filename; 
            } else {
              if (tempImages[1].length===0) { 
                tempImages[1] = res.data.filename; 
              } else {
                if (tempImages[2].length===0) { 
                  tempImages[2] = res.data.filename; 
                } else {
                  if (tempImages[3].length===0) { 
                    tempImages[3] = res.data.filename; 
                  } 
                }
              }
            }
            setImages(tempImages);
            formDataImage1.delete('my-image-file'[0])
            inputFile.current.value = "";
          }
        })
    } 
  }

const handleClickRemoveImage = (index) => {
    const tempImages = images.slice();
    tempImages[index] = '';
    for (let i = 0; i < (tempImages.length-1); i++) {
      if (tempImages[i].length===0) {
        tempImages[i]=tempImages[i+1];
        tempImages[i+1]='';
      }
    }
    setImages(tempImages);
  }

  const handleCategoryChange = event => { 
    if (event.target.value!=0) { setMessageCategory("");}
    setCategoryId(event.target.value)
    setSectionId("0")
    setSubSectionId("0")
  }

  const handleSectionChange = event => {
    if (event.target.value!=0) { setMessageSection("");}
    setSectionId(event.target.value)
    if ((subSectionData.filter((subSection) => { return subSection.sectionId === event.target.value; })).length>0) {
      setSubSectionId("1")
    } else {
      setSubSectionId("0")
    }
  } 
 
  const handleSubSectionChange = event => {
    setSubSectionId(event.target.value)
  } 

//***   const handleSubmit = event => { */
  const handleSave = event => {
    event.preventDefault();

    if (!formData.title) {
      setMessageTitle("Title is a required field.")
      return;
    }

    if (categoryId==0) {
      setMessageCategory("Category is a required field.")
      return;
    }

    if (sectionId==0) {
      setMessageSection("Section is a required field.")
      return;
    }

    if (!formData.price) {
      setMessagePrice("Price is a required field.")
      return;
    }

    if (!postcodeImportId) {
      setMessagePostcodeImportId("Location is a required field.")
      return;
    }
    
    if  ((listingId || id)) { 
      updateListing();  
    } else {
      createListing(); 
    }
    setSubmitting(true)
  }

  const handlePublish = event => {
    event.preventDefault();
    setPublished(true);
    setSubmitting(true);
    updateListing(true);
    // navigate("/listing/"+listingId); e.g. to: http://localhost:3000/listing/109 
// ***    
    window.open("/listing/"+listingId, "_blank", "noreferrer");
  }
    
  const handleDisable = event => {
      event.preventDefault();
      setPublished(false);
      setSubmitting(true);
      updateListing(false);
  }

  return (
      <div className="wrapper">
        <h4><b>Create New Listing</b></h4> 
        <hr/>
        <span  style={{'padding-top': `20px`, 'padding-bottom': `20px`, 'margin': `5px`, 'background-color': `#FFF`}}>
          <a href="/ProductSearch">
            <button style={{'padding': `10px`, 'margin': `10px`}} disabled={(submitting)}  className="btn btn-primary my-2"><b>Search our Database of "Original Products"</b></button>
          </a>
        </span>
        <br/>
        <hr/>
      

        <div>
          {/* <form onSubmit={handleSubmit}> */}
            <fieldset disabled={submitting}>

              <WordBox words={message} mode="success" />

              <div className="form-group" style={{'display': `flex`, width: `600px`}}>
                <label htmlFor="title" style={{'margin-right': `10px`}}><b>Title&nbsp;*</b></label>
                <input 
                  type="text"
                  id="title"
                  name="title" 
                  onChange={handleChange} 
                  value={formData.title || ''}
                  className="form-control border border-dark" 
                />
              </div>
              <WordBox words={messageTitle} />

              <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
                <label htmlFor="category" style={{'margin-right': `10px`}}><b>Category&nbsp;*</b></label>
                <select 
                  id="category"
                  name="category"
                  onChange={handleCategoryChange}
                  className="form-control border border-dark" 
                >
                  <option selected key="A0" value="0">Choose a Category ...</option>
                  { categoryData.map((category, index) => (
                      <option 
                        selected={(category.categoryId===categoryId)} 
                        key={"A"+category.categoryId} 
                        value={category.categoryId}
                      >{category.title}</option>
                    )
                  )}
                </select>
              </div>
              <WordBox words={messageCategory} />

              { ((sectionData.filter((section) => { return section.categoryId === categoryId; })).length>0) && (
                <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
                  <label htmlFor="section" style={{'margin-right': `10px`}}><b>Section&nbsp;*</b></label>
                  <select 
                    id="section"
                    name="section"
                    onChange={handleSectionChange}
                    className="form-control border border-dark"
                  >
                    <option selected key="B0" value="0">Choose a Section ...</option>
                    { sectionData.filter((section) => { return section.categoryId === categoryId; }).map((section, index) => (
                        <option 
                          selected={(section.sectionId===sectionId)} 
                          key={"B"+section.sectionId} 
                          value={section.sectionId}
                        >{section.title}</option>
                      )
                    )}
                  </select>
                </div>
              ) }
              <WordBox words={messageSection} />

              { ((subSectionData.filter((subSection) => { return subSection.sectionId === sectionId; })).length>0) && (
                <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
                  <label htmlFor="subsection" style={{'margin-right': `10px`}}><b>SubSection</b></label>
                  <select 
                    id="subsection"
                    name="subsection"
                    onChange={handleSubSectionChange}
                    className="form-control border border-dark" 
                  >         
                    { subSectionData.filter((subSection) => { return subSection.sectionId === sectionId; }).map((subSection, index) => (
                        <option selected={(subSection.subSectionId===subSectionId)} key={"C"+subSection.subSectionId} value={subSection.subSectionId}>{subSection.title}</option>
                      )
                    )}
                  </select>
                </div>
              ) }
              
              <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
                <label htmlFor="description" style={{'margin-right': `10px`}}><b>Description</b></label>
                <textarea 
                  id="description" 
                  name="description" 
                  rows="10" 
                  cols="80" 
                  onChange={handleChange} 
                  value={formData.description || ''} 
                  style={{width: `600px`}}
                  className="form-control border border-dark" 
                />
              </div>

              <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
                <label htmlFor="price" style={{'margin-right': `10px`}}><b>Price($)&nbsp;*</b></label>
                <input 
                  id="price" 
                  name="price" 
                  size="10" 
                  onChange={handleChange} 
                  value={formData.price || ''}
                  className="form-control border border-dark" 
                />
                { (productId) && (
                  <>  
                    <br/>
                    <b>{ (retailerPrice.length>0) && (" "+retailerName+" Price: $"+retailerPrice)}</b> (At <b>{Utils.formatDate2(retailerPriceDate)}</b>)
                  </>
                )}
              </div>
              <WordBox words={messagePrice} />

              <div className="autocomplete" style={{'display': `flex`, width: `600px`}}>
                <label htmlFor="inputLocation" style={{'margin-right': `10px`, 'font-weight': `700`, 'margin-top': `20px`}}>Item Location&nbsp;*</label>
                <LocationLookup placeholder="Suburb or postcode ..." name="inputLocation" importId={postcodeImportId} onSet={setPostcodeImportId} onFixError={setMessagePostcodeImportId} customWidth="475px" />
              </div>
              <WordBox words={messagePostcodeImportId} />

              <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
                <label htmlFor="isNew" style={{'margin-right': `10px`}}><b>Item Is New?</b></label>
                <input 
                  id="isNew" 
                  name="isNew" 
                  type="checkbox" 
                  onChange={handleCheckBoxChange} 
                  checked={formData.isNew} 
                />
              </div>
                
              <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
                <label htmlFor="showMobile" style={{'margin-right': `10px`}}><b>Show Mobile?</b></label>
                <input 
                  id="showMobile" 
                  name="showMobile" 
                  type="checkbox" 
                  onChange={handleCheckBoxChange} 
                  checked={formData.showMobile} 
                />
              </div>
              <WordBox words={messageMobile} />

              <div className="imageInputWrapper form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
                <div className="imageInputLeft"><b>Images</b> </div> 
                <div className="imageInputLeft ">(Max size is 1MByte)</div> 
                <div className="imageInputRight">
                  <label htmlFor="image__input" className="imageInput btn btn-primary my-2">&nbsp;<b>Choose file</b>&nbsp;</label>
                  <input 
                    id="image__input" 
                    name="image__input" 
                    type="file" 
                    onChange={handleFileInput} 
                    ref={inputFile} 
                    accept="image/*" 
                    style={{display: `none`}} />
                </div>
                <div className="imageInputLeft"  style={{color: `#F00`}}><b> {messageFileSize}</b></div>            
              </div>
              <br/><br/><br/>
              <div className="imageRow form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
                { images.map((image, index) => (
                  (images[index]) && (
                    <div className="imageCol" key={image[index]} style={{ width: `150px` }}> 
                      <img style={{ width: `140px` }} alt="" src={`${FileService.uploadsPath}${images[index].replace('/', '/150_')}`}/>
                      <div style={{justifyContent: `flex-end`}}><button alt="Remove Image" value={index} name={index} onClick={() => handleClickRemoveImage(index)} >X</button></div>
                    </div>
                    )
                  )
                )}
              </div>

            </fieldset>
            <span  style={{'padding-top': `20px`, 'padding-bottom': `20px`, 'margin': `5px`, 'background-color': `#FFF`}}>
              <button style={{'padding': `10px`, 'margin': `10px`}} type="submit" onClick={handleSave} disabled={(submitting)}  className="btn btn-primary my-2"><b>Save Listing</b></button>
            </span>

            { ((listingId) && (!published)) && (
              <span  style={{'paddingTop': `20px`, 'padding-bottom': `20px`, 'margin': `5px`, 'background-color': `#FFF`}}>
                <button style={{'padding': `10px`, 'margin': `10px`}}  type="submit" onClick={handlePublish}  className="btn btn-primary my-2"><b>Save and Publish</b></button>
              </span>
            )}

            <span  style={{'paddingTop': `20px`, 'padding-bottom': `20px`, 'margin': `5px`, 'background-color': `#FFF`}}>
              
              <button style={{'padding': `10px`, 'margin': `10px`}} type="submit" onClick={handleDisable} name="disableButton" disabled={((published) ) ? false : true} className="btn btn-primary my-2"><b>Disable Listing</b></button>
            </span>
          {/* </form> */}
        </div>
      </div>
    
  );
}

export default CreateListing;