import axios from "axios";

let strDEVBaseURL = "http://localhost:5000/api"
// let strDEVBaseURL = "https://passabuck.com/api"
let strLIVEBaseURL = "https://passabuck.com/api"

let strHostname = window.location.hostname
console.log("hostname " + strHostname);

const strLIVEPATH = "/home/justin/"
// const strDEVPATH = "/Users/justina/"

let strProcessEnv = JSON.stringify(process.env);
console.log(strProcessEnv)
// let strBaseURL = strProcessEnv.includes(strLIVEPATH) ? strLIVEBaseURL : strDEVBaseURL;
let strBaseURL = strHostname.includes("localhost") ? strDEVBaseURL : strLIVEBaseURL;
console.log(strBaseURL)

export default axios.create({
  baseURL: strBaseURL,
});

// export default axios.create({
//   baseURL: strBaseURL,
//   headers: {
//     "Content-type": "application/json"
//   }
// });
