import axios from "../http-common";

class BrandDataService {
 
  findByTitle(title) {
    if (title.length >= 3) {
      return axios.get(`/brands/title/${title}`); 
    }
  }
  // API e.g.: http://localhost:5000/api/brands/title/bos

  getBrands = (value, setBrands) => {
    // *** check that "value" consists only of allowed characters 
    this.findByTitle(value) 
      .then(response => {
        console.log(response.data)
        setBrands(response.data);
      })
      .catch(e => {
        console.log(e);
      });
 
  };
    
}

export default new BrandDataService();
