import React from 'react';

function WordBox({ words, mode='danger' }) {

  return (
    <>
      { (words) && (
        <div className="form-group" style={{'margin-top': `10px`, width: `600px`}}>
          <div className={`alert alert-`+mode} role="alert"> 
            {words}
          </div>
        </div>
      )}
    </>
  );
}

export default WordBox;
