import axios from "../http-common";

class ProductDataService {
  get(id) {
    return axios.get(`/products/${id}`);
  }
 
  findByTitle(title) {
    return axios.get(`/products/title/${title}`);
  }

  findBySearch(title, includeDescription, retailerName, brandName, pageSize=10, pageNumber=1, sortById=0) {
    console.log("findBySearch"); 
    return axios
      .post(`/products/search/`, {
        title,
        retailerName,
        includeDescription,
        brandName,
        pageSize,
        pageNumber,
        sortById
      })
  };

  create(data) {
    return axios.post("/products", data);
  }

  update(id, data) {
    return axios.put(`/products/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/products/${id}`);
  }

}

export default new ProductDataService();
