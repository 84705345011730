import React, { useState, useEffect } from "react";
import PostcodeDataService from "../../services/PostcodeService";
import ProfileAuthService from "../../services/profile.auth.service";

function LocationLookup({ importId, onSet, onFixError, customWidth="380px", customHeight="38px", placeholder="" } ) {
  
  const [currentPostcodes, setCurrentPostcodes] = useState([]);
  const [suburb, setSuburb] = useState("");
  const [timerLocation, setTimerLocation] = useState(null);
  const [currentFocusLocation, setCurrentFocusLocation] = useState(-1);
  
  useEffect(() => {
    if (importId && importId.length>0) {
      getPostcode(importId);
    } else {
      const profile = ProfileAuthService.getCurrentProfile();
      if (profile) {
        getPostcode(profile.postcodeImportId);
      }
    }
  }, [importId]);

  const getPostcode = pcImportId => {
    PostcodeDataService.findByImportId(pcImportId)
      .then(response => {
        setSuburb(PostcodeDataService.formatLocality(response.data[0])); 
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleChangeLocation = async (e) => {
    setSuburb(e.target.value);
    if (!e.target.value) { onSet(null); }
    if ((e.target.value).length >= 4) {
      clearTimeout(timerLocation);
      let timeout = setTimeout(() => {
        PostcodeDataService.getPostcodes(e.target.value, setCurrentPostcodes)
      }, 300);
      setTimerLocation(timeout);
    } else {
      setCurrentPostcodes([]);
    }
  }

  const handleClickLocation = (value, clickedPostcodeImportId) => {
    setSuburb(value);
    setCurrentPostcodes([]);
    setCurrentFocusLocation(-1);
    document.getElementById("inputLocation").focus();
    onSet(clickedPostcodeImportId);
    onFixError("")
  } 

  const handleKeyDownLocation = event => {
    
    if (event.keyCode == 40) {
      if (currentPostcodes) {
        if (currentFocusLocation < (currentPostcodes.length - 1)) {
          setCurrentFocusLocation(currentFocusLocation+1); 
        }
      }      
    } else if (event.keyCode == 38) {
      if (currentPostcodes) {
        if (currentFocusLocation >= 0) {
          setCurrentFocusLocation(currentFocusLocation-1); 
        }
      }
    } else if (event.keyCode == 13) {
      if (currentFocusLocation >= 0) {
        event.preventDefault();
        setSuburb(PostcodeDataService.formatLocality(currentPostcodes[currentFocusLocation]));
        setCurrentPostcodes([]);
        setCurrentFocusLocation(-1);
        onSet((currentPostcodes[currentFocusLocation]).importId);
        onFixError("")
      }      
    }
  } 
  



    // className={((index%2)==0) ? "wrapper" : ""}>
    // { (currentListing.image1.length>0) && (
    //     <div style={{'float': `left`, 'paddingRight': `15px`}}>
    //       <img alt="" src={`${FileService.uploadsPath}${currentListing.image1.replace('/', '/150_')}`}/>

    //         placeholder="Suburb or postcode ..."

  return (
    <div className="autocomplete"  style={{'display': `flex`, width: `${customWidth}`, height: `${customHeight}`, 'margin-top': `00px`}}>
      
      <input
        id="inputLocation" 
        type="text" 
        name="inputLocation"
        value={suburb}
        onChange={handleChangeLocation}
        onKeyDown={handleKeyDownLocation}
        placeholder={placeholder}
        autocomplete="off" 
        className="form-control border border-dark"
      />
      <div id="autocomplete-list" className="autocomplete-items" >
        {currentPostcodes.map((currentPostcode, index) => (            
            <div
              className={(index==currentFocusLocation) && "autocomplete-active"}
              value={PostcodeDataService.formatLocality(currentPostcode)} 
              onClick={() => handleClickLocation(PostcodeDataService.formatLocality(currentPostcode), currentPostcode.importId)}
            >
              {PostcodeDataService.formatLocality(currentPostcode)}
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default LocationLookup;