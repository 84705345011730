import React from "react";
import { categoryData, sectionData, subSectionData, hierarchyPath } from "../../data/hierarchy";

function SiteMap() {
  return (
    <div>
      <h1>Site Map</h1>
      <div>
        <b>Categories - - - - - - - Sections - - - - - - - Sub-Sections</b> 
        <br/><br/>
        <p>  
            { categoryData.map((category) => (
                <>
                    <span>
                    <a class="btn-link" href={hierarchyPath(category)}>{category.title}</a> <br/>
                        { sectionData.filter((section) => { return section.categoryId === category.categoryId; }).map((section) => (
                            <span>
                                - - - - - - - - - - - - - - - - <a class="btn-link" href={hierarchyPath(category, section)}>{section.title}</a> <br/>
                                { subSectionData.filter((subSection) => { return subSection.sectionId === section.sectionId; }).map((subSection) => (
                                    <span>
                                        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - <a class="btn-link" href={hierarchyPath(category, section, subSection)}>{subSection.title}</a> <br/>
                                    </span>
                                ) )}
                            </span>
                        ) )}
                    </span>
                </>
            ) )}           
        </p>
      </div>
    </div> 
  );
};

export default SiteMap;
