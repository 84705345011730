import React, { useState, useEffect, useReducer } from "react";
import { Link } from 'react-router-dom';
import ProductDataService from "../../services/ProductService";
import BrandDataService from "../../services/BrandService";
import { retailerData } from "../../data/hierarchy";
import FileService from "../../services/FileService"; 
import Utils from '../../services/utils';

const defaultRetailerId="0";
const defaultSortById="1";

const formReducer = (state, event) => {
  if(event.reset) {
    return {
      title: '',
      includeDescription: false,
    }
  }
  return {
    ...state,
    [event.name]: event.value
  }
}

function ProductSearch() {
  const [currentProducts, setCurrentProducts] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [retailerId, setRetailerId]= useState(defaultRetailerId);
  const [sortById, setSortById]= useState(defaultSortById);

  const [currentBrands, setCurrentBrands] = useState([]);
  const [timerBrand, setTimerBrand] = useState(null);
  const [currentFocusBrand, setCurrentFocusBrand] = useState(-1);
  const [brandName, setBrandName] = useState(""); 

  const pageSize = Utils.defaultPageSize
  const [pageNumber, setPageNumber] = useState(1);

  const [formData, setFormData] = useReducer(formReducer, {
    title: 'drill',
    includeDescription: false,
  });
  
  const getProducts = (newPageNumber) => {
    // ProductDataService.findByTitle(formData.title)
    let retailerName = "";
    retailerData.map((retailer, index) => {
      if (retailer.retailerId==retailerId) {retailerName=retailer.title;} 
    })
  
    ProductDataService.findBySearch(formData.title, formData.includeDescription, retailerName, brandName, pageSize, newPageNumber, sortById)
      .then(response => {
        setSubmitting(false)
        setCurrentProducts(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  // useEffect(() => {
  //   if (formData.title)
  //     getProducts(formData.title);
  // }, [formData.title]);

  const handleSubmit = event => {
    event.preventDefault();
    setSubmitting(true);
    getProducts(1);
  }

  const handleChange = event => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  }
  const handleCheckBoxChange = event => {
    setFormData({
      name: event.target.name,
      value: (event.target.checked===true) ? true : false,
    });
  }

  const handleRetailerChange = event => { 
    setRetailerId(event.target.value)
  }

  const handleSortByChange = event => { 
    setSortById(event.target.value)
  }

  const handleClickToListing = event => { 
    // e.g. /CreateListing/product/:productId 
  }

  const handleClickPrevious = event => { 
    setPageNumber(pageNumber-1);
    getProducts(pageNumber-1);
  }

  const handleClickNext = event => { 
    setPageNumber(pageNumber+1);
    getProducts(pageNumber+1);

  }

  const handleChangeBrandName = async (e) => {
    setBrandName(e.target.value)
    if ((e.target.value).length >= 3) {
      clearTimeout(timerBrand);
      let timeout = setTimeout(() => {
        BrandDataService.getBrands(e.target.value, setCurrentBrands)
      }, 300);
      setTimerBrand(timeout);
    } else {
      setCurrentBrands([]);
    }
  }

  const handleClickBrandName = (clickedBrandName) => {
    setBrandName(clickedBrandName)
    setCurrentBrands([]);
    setCurrentFocusBrand(-1)
    document.getElementById("brandName").focus();
  } 

  const handleKeyDownBrandName = event => {
    
    if (event.keyCode == 40) {
      if (currentBrands) {
        if (currentFocusBrand < (currentBrands.length - 1)) {
          setCurrentFocusBrand(currentFocusBrand+1); 
        }
      }      
    } else if (event.keyCode == 38) {
      if (currentBrands) {
        if (currentFocusBrand >= 0) {
          setCurrentFocusBrand(currentFocusBrand-1); 
        }
      }
    } else if (event.keyCode == 13) {
      event.preventDefault();
      if (currentFocusBrand >= 0) {
        setBrandName((currentBrands[currentFocusBrand]).title);
        setCurrentBrands([]);
        setCurrentFocusBrand(-1);
        document.getElementById("brandName").focus();    
      }      
    }
  }  

  return (
    <div>
      <h1>Search Original Products Database</h1>
      {submitting &&
        <div>
          Submitting 
        </div>
      } 
      <form onSubmit={handleSubmit}>
        <fieldset disabled={submitting}>
          <label>
            <p><b>Search Title</b> <input name="title" required class="w-25" onChange={handleChange} value={formData.title || ''}/> <input type="checkbox" name="includeDescription" onChange={handleCheckBoxChange} checked={formData.includeDescription} /> Include Description? </p>
          </label>

          <label>
            <p><b>Retailer</b> <select onChange={handleRetailerChange} defaultValue={defaultRetailerId}>
                <option key="D0" value="0">Choose Retailer ...</option>
                { retailerData.map((retailer, index) => (
                    <option key={"D"+retailer.retailerId} value={retailer.retailerId}>{retailer.title}</option>
                  )
                )}
              </select>
            </p>
          </label>


          <div className="autocomplete">
            <p><b>Brand</b> <input 
              id="brandName" 
              type="text" 
              name="brandName" 
              value={brandName}
              onChange={handleChangeBrandName} 
              onKeyDown={handleKeyDownBrandName} 
              autocomplete="off" 
            /></p>
            <div id="autocomplete-list" className="autocomplete-items" >
              { currentBrands.map((currentBrand, index) => (            
                <div
                  className={(index==currentFocusBrand) && "autocomplete-active"}
                  value={currentBrand.title} 
                  onClick={() => handleClickBrandName(currentBrand.title)}
                >
                  {currentBrand.title}
                </div>
                )
              )}
            </div>
          </div>


          <label>
            <p><b>Sort By</b> <select onChange={handleSortByChange} defaultValue={defaultSortById}>
                <option key="E0" value="0">Default</option>
                <option key="E1" value="1">Brand</option>
                <option key="E2" value="2">Price (lowest first)</option>
                <option key="E3" value="3">Price (highest first)</option>
                <option key="E4" value="4">Retailer</option>
                <option key="E5" value="5">Title</option>
              </select>
            </p>
          </label>

          <br/><br/>
          <span style={{'padding-top': `20px`, 'padding-bottom': `20px`, 'margin': `0px`, 'background-color': `#FFF`}}>
            <button type="submit" disabled={(submitting)} className="imageInput" style={{'padding': `10px`, 'margin': `10px`}}>Search Products</button>
          </span>
        </fieldset>
      </form>
      
      <div>
        { currentProducts && (
          <>
            <h4>Search Results </h4>
            { currentProducts.map((currentProduct, index) => (
              ( (index!=pageSize) && (
                <div style={{'display': `flex`}}>
                  { (currentProduct.retailerName==="ALDI" ) && (
                    <div style={{'float': `left`, 'padding-right': `15px`}}>
                        <img alt="" src={currentProduct.image_url} />
                        <br/><br/>
                    </div>
                  )}
                  { (currentProduct.retailerName==="Bunnings") && (
                    <div style={{'float': `left`, 'padding-right': `15px`}}>
                        <img alt="" src={FileService.productsPath+"Bunnings/Bunnings-"+currentProduct.id+".jpg"} />
                        <br/><br/>
                    </div>
                  )}
                  { (currentProduct.retailerName==="IKEA" ) && (
                    <div style={{'float': `left`, 'padding-right': `15px`}}>
                        <img  style={{'width': `150px`, 'height': `150px`}} alt="" src={FileService.productsPath+"Ikea/"+currentProduct.image_url.replace("https://www.ikea.com/au/en/images/products/", "").replace(".jpg", "-150x150.jpg")} />
                        <br/><br/>
                    </div>
                  )}
                  <div style={{'float': `right`}}>
                    <span><b>{currentProduct.title}</b></span><br/>
                    <span>{Utils.scrubOutHTML(currentProduct.description)}</span><br/>
                    <label><b>{currentProduct.retailerName} Price</b> (At <b>{Utils.formatDate2(currentProduct.updatedAt)}): ${currentProduct.price} {currentProduct.amount}</b> </label>
                    <Link to={`/CreateListing/product/${currentProduct.id}`}>
                      <button alt="Use to Create New Listing" value={index} name={index} >Use to Create New Listing</button> </Link> 
                    <a href={currentProduct.href} target="_blank">
                      <button alt="See Retailer Website " value={index} name={index} onClick={() => handleClickToListing(index)} >See Retailer Website</button>
                    </a>
                    <br/><br/>
                  </div>
                </div>
              ))
              
            ) )}

            { (pageNumber>1) && (
              <span style={{'padding-top': `20px`, 'padding-bottom': `20px`, 'margin': `0px`, 'background-color': `#FFF`}}>
                <button type="submit" className="imageInput" onClick={handleClickPrevious} style={{'padding': `10px`, 'margin': `10px`}}>Prev Page</button>
              </span>
            )}            
            { (currentProducts.length>pageSize) && (
              <span style={{'padding-top': `20px`, 'padding-bottom': `20px`, 'margin': `0px`, 'background-color': `#FFF`}}>
                <button type="submit" className="imageInput" onClick={handleClickNext} style={{'padding': `10px`, 'margin': `10px`}}>Next Page</button>
              </span>
            )}

          </>
        )}
      </div>
    </div>
  );
};

export default ProductSearch;
