import axios from "../http-common";

class PostcodeDataService {
  get(id) {
    return axios.get(`/postcodes/${id}`);
  }

  findByLocality(locality) {
    return axios.get(`/postcodes/locality/${locality}`);
  }

  findByPostcode(strPostcode) {
    return axios.get(`/postcodes/postcode/${strPostcode}`);
  }

  // TEST: 4483 (Sydney, 2000)
  // http://localhost:5000/api/postcodes/importId/4483 ${importId}
  findByImportId(importId) {
    return axios.get(`/postcodes/importId/${importId}`);
  }

  formatLocality(postcodeDatum) {
    let str = postcodeDatum.locality.toLowerCase();
    str = str.split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ') + " " + postcodeDatum.state + " " + postcodeDatum.postcode;
  }
   
  getPostcodes = (value, setPostcodes) => {

    // check if "value" consists only of numbers (to search for postcode match)
    if (/^[0-9]+$/.test(value)) {
      this.findByPostcode(value) // API e.g.: http://localhost:5000/api/postcodes/postcode/2000 
       .then(response => {
         setPostcodes(response.data);
       })
       .catch(e => {
         console.log(e);
       });
   } else {
      this.findByLocality(value) // API e.g.: http://localhost:5000/api/postcodes/locality/bal  
       .then(response => {
         setPostcodes(response.data);
       })
       .catch(e => {
         console.log(e);
       });
   };
 };

}

export default new PostcodeDataService();
