import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'; 
import ProfileAuthService from "../../services/profile.auth.service";

import { isEmail } from "validator";

const PasswordReset = () => {

  const { resetToken } = useParams(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [showSubmitPassword, setShowSubmitPassword] = useState(true);

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setMessage("");
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setMessage("");
  };

  const onSubmitEmail = (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!email){
      setErrorMessage("Please enter your email address!")
      return;
    }

    if (!isEmail(email)) {
      setErrorMessage("Please enter a valid email address!")
      return;
    }

    setMessage("");
    setLoading(true);

    ProfileAuthService.resetPasswordEmail(email)
    .then(
      () => {
        setMessage("If you registered with this email address, we have sent you further instructions. Please check your inbox.");
        setLoading(false);
      },
      (error) => {
        const resMessage = ((error.response && error.response.data && error.response.data.message) || error.message || error.toString());
        setErrorMessage(resMessage);
        setLoading(false);
      }
    );
  };

  const onSubmitPassword = (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!password) {
      setErrorMessage("Password is a required field.")
      return;
    }
    if (password.length < 8 || password.length > 40) { 
      setErrorMessage("The password must be between 8 and 40 characters.")
      return;
    }

    setMessage("");
    setLoading(true);

    ProfileAuthService.resetPassword(password, resetToken)
    .then(
      () => {
        setMessage("Your Password has been updated");
        setLoading(false);
        setShowSubmitPassword(false);
      },
      (error) => {
        const resMessage = ((error.response && error.response.data && error.response.data.message) || error.message || error.toString());
        setErrorMessage(resMessage);
        setLoading(false);
      }
    );
  };


  return (
    <div className="wrapper">
      <div style={{'text-align': `center`, 'margin-top': `5px`}}><h4><b>Password Reset</b></h4></div>
      <hr style={{'margin-top': `10px`}}/>
      <div className="card card-container" style={{'margin-top': `10px`}}>
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
          style={{'margin-bottom': `15px`}}
        />
          
        {(!resetToken) ? (
          <form onSubmit={onSubmitEmail}>
            
                <div className="form-group" style={{'display': `flex`, width: `270px`}}>
                  <label htmlFor="email" style={{'margin-right': `10px`, 'font-weight': `700`}}>Email</label>
                  <input
                    type="text"
                    className="form-control border border-dark"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                  />
                </div>

                <div className="form-group" style={{'display': `flex`, width: `270px`, 'margin-top': `10px`}}>
                  <button disabled={loading} className="btn btn-primary btn-block my-2" style={{'font-weight': `700`, 'width': `270px`}}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Send Reset Instructions</span>
                  </button>
                </div>

          </form>
        ) : (
          <>
            {showSubmitPassword && (
              <form onSubmit={onSubmitPassword}>
                    <div className="form-group" style={{'display': `flex`, width: `270px`}}>
                      <label htmlFor="password" style={{'margin-right': `10px`, 'font-weight': `700`}}>New Password</label>
                      <input 
                        type="password"
                        className="form-control border border-dark"
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                      />
                    </div>

                    <div className="form-group" style={{'display': `flex`, width: `270px`, 'margin-top': `10px`}}>
                      <button disabled={loading} className="btn btn-primary btn-block my-2" style={{'font-weight': `700`, 'width': `270px`}}>
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Save New Password</span>
                      </button>
                    </div>
              </form>
            )}
          </>
        )}


        {errorMessage && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert"  style={{'margin': `0px`, 'padding': `12px`}}>
              {errorMessage}
            </div>
          </div>
        )}
        
        {message && (
          <div className="form-group">
            <div className="alert alert-success" role="alert"  style={{'margin': `0px`, 'padding': `12px`}}>
              {message}
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default PasswordReset;
