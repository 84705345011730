import axios from "../http-common";  
import authHeader from "./auth-header";
const API_PATH = "/image-upload";

class FileService {
  productsPath="/img/products/";
  uploadsPath="/img/uploads/";
  maxUploadFileSize = 1048576;

  uploadImage(image) {
    return axios.post(API_PATH, image, { headers: authHeader() });
  }


} 
 
export default new FileService(); 