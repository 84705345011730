import React, { useState } from 'react';
import MessageDataService from "../../services/MessageService";
// import ProfileAuthService from "../../services/profile.auth.service";
 
function MessageForm({ listingId, buyerProfileId, wasFromBuyer, onSend, rowId }) {
 
  const [submitting, setSubmitting] = useState(false);
  const [messageContent, setMessageContent] = useState("");

  const createMessage = () => {

    var data = {
      listingId: listingId,
      buyerProfileId: buyerProfileId, 
      wasFromBuyer: wasFromBuyer, 
      content: messageContent,
      wasSent: false
    }

    MessageDataService.create(data)
      .then(response => {
        // id: response.data.id // Might need to be checked
        setMessageContent("")
        setSubmitting(false);
        console.log(response.data);
        onSend(rowId);
      })
      .catch(e => {
          console.log(e);
          setSubmitting(false);
      });
  }; 

  const handleSubmitMessage = event => {
    event.preventDefault();
    setSubmitting(true);
    createMessage(); 
  }

  const handleChangeMessage = event => {
    setMessageContent(event.target.value)
  }

  return (
    <>
      {submitting &&
        <div>
          Submitting 
        </div>
      } 
      <form onSubmit={handleSubmitMessage}>
        <fieldset disabled={submitting}>
          <div className="form-group" style={{'display': `flex`, width: `600px`}}>
            <span>
              <input 
                autoFocus 
                className="form-control border border-dark message-reply" 
                placeholder="Send Message ..." 
                name="messageContent" 
                required 
                onChange={handleChangeMessage} 
                value={messageContent || ''}
              />
            </span>&nbsp;
            <button className="btn btn-primary btn-block my-2" style={{'font-weight': `700`, 'width': `100px`}}
                type="submit" 
                disabled={(submitting)}
              >&nbsp;&nbsp;Send&nbsp;&nbsp;</button>
            </div>

        </fieldset> 
      </form>
    </>
  );
}

export default MessageForm;
