import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useSearchParams } from 'react-router-dom'; 
import ProfileAuthService from "../../services/profile.auth.service";

const Login = () => {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const form = useRef();

  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [refPage, setRefPage] = useState("");
  const [currentProfile, setCurrentProfile] = useState({});
  
  const [password, setPassword] = useState("12345678");
  
  useEffect(() => {
    console.log("useEffect 1 ");
    let refPageParam=searchParams.get('refPage'); 
    if (refPageParam && refPageParam.length>0) { 
      setRefPage(refPageParam); 
      console.log('refPageParam '+refPageParam);
    }
  }, []);

  useEffect(() => {
    console.log("useEffect 2");
    console.log('u2 refPage '+refPage);
    const profile = ProfileAuthService.getCurrentProfile();
    if (profile) {
      setCurrentProfile(profile);
      console.log('u22 refPage '+refPage);
      if (refPage.length>0) { navigate(refPage); }
    }  
  }, [refPage]);
  
  const handleLogOut = () => {
    ProfileAuthService.logout();
    window.location.reload();
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setMessage("");
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setMessage("");
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();

    if ((!email) || (!password)) {
      setMessage("Please enter email and password!")
      return;
    }

    setMessage("");
    setLoading(true);

    ProfileAuthService.login(email, password)
    .then(
      () => {
        // navigate("/Profile");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
};

  return (
    <div className="wrapper">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
          style={{'margin-bottom': `15px`}}
        />
        <form onSubmit={onSubmitLogin} ref={form}>
        {/* NOTE: First condition MIGHT be redundant due to navigate("/Profile") above */}
        { !(currentProfile.id) ? (
          <>
              <div className="form-group" style={{'display': `flex`, width: `270px`}}>
                <label htmlFor="email" style={{'margin-right': `10px`, 'font-weight': `700`}}>Email</label>
                <input
                  type="text"
                  className="form-control border border-dark"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                />
              </div>

              <div className="form-group" style={{'display': `flex`, width: `270px`, 'margin-top': `10px`}}>
                <label htmlFor="password" style={{'margin-right': `10px`, 'font-weight': `700`}}>Password</label>
                <input
                  type="password"
                  className="form-control border border-dark"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                />
              </div>
              <br/> Forgot Password? <Link to="/PasswordReset" class="btn btn-link" style={{'padding': `0px 0px`}}><b>Reset here</b></Link>
              <div className="form-group" style={{'display': `flex`, width: `270px`, 'margin-top': `10px`}}>
                <button disabled={loading} className="btn btn-primary btn-block my-2" style={{'font-weight': `700`, 'width': `270px`}}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert"  style={{'margin': `0px`, 'padding': `12px`}}>
                    {message}
                  </div>
                </div>
              )}

            <div>
              
              
              <br/> Not a Member? <Link to="/Register" class="btn btn-link" style={{'padding': `0px 0px`}}><b>Register here</b></Link>
            </div>
          </>
        ) : (
          <div>
            <br/>
            
            You are logged in as <Link to={"/Profile"}>{currentProfile.firstName} {currentProfile.lastName}</Link>
            <br/><br/>
            <Link to={"/Login"} onClick={handleLogOut}><button className="btn btn-primary">Logout</button></Link>
          </div>
        )
        }

        </form>
      </div>
    </div>
  );
};

export default Login;
