import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import ListingDataService from "../../services/ListingService";
import FileService from "../../services/FileService"; 
import Utils from '../../services/utils';
import LocationLookup  from '../App/LocationLookup';

function ListingSearch() {

  const { paramTitle, paramImportId } = useParams(); 

  const [currentListings, setCurrentListings] = useState([]);
  const [title, setTitle] = useState("");
  const [postcodeImportId, setPostcodeImportId] = useState("");
  const [messagePostcodeImportId, setMessagePostcodeImportId] = useState("");

  const [showResults, setShowResults] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (paramTitle && paramTitle.length>0) {
      setTitle(paramTitle);
      getListingsFromParams();
    }

    if (paramImportId && paramImportId.length>0) {
      setPostcodeImportId(paramImportId);
      getListingsFromParams();
    }

    
  }, []);
  
  const getListingsFromParams = () => {
    console.log("getListingsFromParams")
    console.log(paramTitle)
    console.log(paramImportId);
    
    ListingDataService.getPublishedKeywordAndLocation(paramTitle, paramImportId) 
      .then(response => {
        setSubmitting(false);
        setCurrentListings(response.data);
        setShowResults(true);
        console.log(response.data);
      })
      .catch(e => {
        setSubmitting(false);
        setShowResults(false); 
        console.log(e);
      });
  };
  
  const getSearchListings = () => {
  
    ListingDataService.getPublishedKeywordAndLocation(title, postcodeImportId) 
      .then(response => {
        setSubmitting(false);
        setCurrentListings(response.data);
        setShowResults(true);
        console.log(response.data);
      })
      .catch(e => {
        setSubmitting(false);
        console.log(e);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setShowResults(false);

    if (!title) {
      // setMessageTitle("Keyword is a required field.")
      return;
    }

    if (title.length < 4 || title.length > 40) { 
      //setMessageTitle("The ... must be between 4 and 40 characters.")
      return;
    }

    // This is to accommodate AUS-wide searches
    // if (!postcodeImportId) {
    //   setMessagePostcodeImportId("Location is a required field.")
    //   return;
    // }

    setSubmitting(true);
    getSearchListings();
  }

  const handleChangeTitle = event => {
    setTitle(event.target.value);
  }

  return (
    <div>
      <h4><b>Search Listings</b></h4>
      <form onSubmit={handleSubmit}>
        <fieldset disabled={submitting}>

        <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
          <label htmlFor="title" style={{'margin-right': `5px`, 'font-weight': `700`}}>Search&nbsp;for&nbsp;</label>
          <input 
            placeholder="What ..." 
            name="title" type="text" 
            onChange={handleChangeTitle} 
            value={title} 
            className="form-control border border-dark" />
        </div>

        <div className="autocomplete" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
          <label htmlFor="inputLocation" style={{'margin-right': `22px`, 'font-weight': `700`, 'margin-top': `10px`}}>Location</label>
          <LocationLookup 
            placeholder="Where (Suburb or postcode ... )"
            name="inputLocation" 
            importId={postcodeImportId} 
            onSet={setPostcodeImportId} 
            onFixError={setMessagePostcodeImportId} 
            customWidth="520px" 
          />
        </div>

          <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
            <button type="submit" disabled={submitting} className="btn btn-primary btn-block my-2" style={{'font-weight': `700`, 'width': `600px`}}>
              {submitting && (
                <span className="spinner-border spinner-border-sm" />
              )}
                Search
            </button>
          </div>

          {messagePostcodeImportId && (
                <div className="form-group" style={{'margin-top': `10px`}}>
                  <div className="alert alert-danger" role="alert">
                    {messagePostcodeImportId}
                  </div>
                </div>
          )}

        </fieldset>
      </form>
      
      <div>
        { ((currentListings) && (showResults)) &&  (
          <>
            { (currentListings.length>0) ? (
              <h4>Search Results</h4>
            ) : (
              <h4>No Results</h4>
            ) }
            { currentListings.map((currentListing, index) => (
                <div key={index} style={{'display': `flex`, 'padding': `15px 20px`}} className={((index%2)==0) ? "wrapper" : ""}>
                  { (currentListing.image1.length>0) && (
                    <div style={{'float': `left`, 'paddingRight': `15px`}}>
                      <img alt="" src={`${FileService.uploadsPath}${currentListing.image1.replace('/', '/150_')}`}/>
                    </div>
                  )}
                    <div style={{'float': `right`}}>
                      { (currentListing.external) ? (
                        <h5><span><a href={currentListing.url} class="btn-link" target="_blank" style={{'text-decoration': `none`, 'font-weight': `900`}}>{currentListing.title}&nbsp;{Utils.formatPrice(currentListing.price)}</a></span></h5> 
                      ) : (
                        <h5><span><Link class="btn-link" to={`/listing/${currentListing.id}`} style={{'text-decoration': `none`, 'font-weight': `900`}}>{currentListing.title}&nbsp;{Utils.formatPrice(currentListing.price)}</Link></span></h5>
                      )}
                      <span>{Utils.scrubOutHTML(currentListing.description)}</span>
                      <br/><br/>
                    </div>
                  </div>
              )
            )}
          </>
        )}
      </div>

    </div>
  );
};

export default ListingSearch;
