import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ProfileDataService from "../../services/profile.auth.service";

function VerifyEmail() {

    const { token } = useParams();
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (token)
            console.log("token in useParams: " + token);
            ProfileDataService.verifyEmail(token)
                .then(response => {
                setMessage(response.data.message)
            })
        }, [token]);
    
  return ( 
    <div>
        <p>
            <h5>{message}</h5>
        </p>
    </div>
  );
};

export default VerifyEmail;
