import React from "react";
import { categoryData, sectionData, subSectionData, hierarchyPath } from "../../data/hierarchy";

const BreadCrumbs = ({categoryId ,sectionId ,subSectionId}) => {

    // *** TO Link Section & SubSections, ... 
    // *** Either identify category & section, to create respective objects prior to render for use in hierarchyPath()
    // *** Or modify hierarchyPath() to use {categoryId ,sectionId ,subSectionId} 
    // *** create & use hierarchyPath-BY-ID() that uses {categoryId ,sectionId ,subSectionId} 

    return (
        <div style={{'marginBottom': `20px`}}>
            { categoryData.filter((categoryDatum) => { return (categoryDatum.categoryId == categoryId) }).map((category) => (
                <span><a href={hierarchyPath(category)}>{category.title}</a></span> 
                )
            )}
            { sectionData.filter((sectionDatum) => { return (sectionDatum.sectionId == sectionId) }).map((section) => (
                <span> - {section.title}</span> 
                )
            )}
            { subSectionData.filter((subSectionDatum) => { return (subSectionDatum.subSectionId == subSectionId) }).map((subSection) => (
                <span> - {subSection.title}</span>  
                )
            )}
        </div>
    );
};

export default BreadCrumbs;
