import axios from "../http-common";
import authHeader from "./auth-header";

class ListingDataService {
  get(id) {
    return axios.get(`/listings/${id}`);
  }
  // http://localhost:5000/api/listings/12 
  
  getWithSeller(id) {
    return axios.get(`/listings/withSeller/${id}`);
  }

  getPublished() {
    return axios.get(`/listings/published`);
  }
  
  
  getMyListings() {
    return axios.post("/listings/myListings/", {}, { headers: authHeader() });
  }

  create(data) {
    return axios.post("/listings", data, { headers: authHeader() });
  }

  update(id, data) {
    return axios.put(`/listings/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/listings/${id}`);
  }
 
  getPublishedWithLocality(categoryId, sectionId, subSectionId) {
    let apiCategoryId = "0"
    let apiSectionId = "0"
    let apiSubSectionId = "0"

    if (categoryId) {apiCategoryId = categoryId;}
    if (sectionId) {apiSectionId = sectionId;}
    if (subSectionId) {apiSubSectionId = subSectionId;}
    
    console.log(`/listings/publishedWithLocality/${apiCategoryId}/${apiSectionId}/${apiSubSectionId}`)
    return axios.get(`/listings/publishedWithLocality/${apiCategoryId}/${apiSectionId}/${apiSubSectionId}`); 
  }

  getPublishedKeywordAndLocation(keyword, importId) { 

    return axios.get(`/listings/publishedKeywordAndLocation/${keyword}/${importId}`); 
  // e.g. http://localhost:5000/api/listings/publishedKeywordAndLocation/microsoft/4483
  //      http://localhost:5000/api/listings/publishedKeywordAndLocation/gary/
   
  }
}

export default new ListingDataService(); 
