import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import ProfileAuthService from "../../services/profile.auth.service";
import MessageDataService from "../../services/MessageService";
import MessageForm from "../Message/MessageForm";
import Utils from '../../services/utils';
import angle_down from '../../img/angle-down.svg';
import envelope from '../../img/envelope.svg';
 
const MyMessages = () => {
  const [currentProfile, setCurrentProfile] = useState({});
  const [currentSellerMessages, setCurrentSellerMessages] = useState([]);
  const [currentBuyerMessages, setCurrentBuyerMessages] = useState([]);
  const [hasUnreadSellerMessages, setHasUnreadSellerMessages] = useState([]);
  const [hasUnreadBuyerMessages, setHasUnreadBuyerMessages] = useState([]);
  const [openSellerMessages, setOpenSellerMessages] = useState([]);
  const [openBuyerMessages, setOpenBuyerMessages] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const profile = ProfileAuthService.getCurrentProfile();
    if (profile) {
      setCurrentProfile(profile);
    } else {
      navigate('/Login?refPage=%2FMessages%2FAllMessages')
    }  
  }, [])

  useEffect(() => {
    getMessages();
  }, [currentProfile]);

  const getMessages = (rowId=-1) => {
    if (currentProfile) {
      MessageDataService.getForSellerByProfileId(currentProfile.id)        // Messages for My Listings [SELLER View] (0 ... n conversations, 0...n per Listing, each with 0...m per Buyer)
        .then(response => {
          let messagesDataSeller = response.data
          setCurrentSellerMessages(messagesDataSeller)
          console.log("messagesDataSeller" + JSON.stringify(messagesDataSeller))

          let tempHasUnreadSellerMessages = new Array(messagesDataSeller.length).fill(false);
          let messagesStartId = 0 
          messagesDataSeller.map((currentMessage, index) => { 
            if ((index>0) && ((currentMessage.buyerProfileId !== messagesDataSeller[index-1].buyerProfileId) || (currentMessage.listingId !== messagesDataSeller[index-1].listingId))) { messagesStartId=index}
            if ((currentMessage.wasRead==0) && (currentMessage.wasFromBuyer==1)) {tempHasUnreadSellerMessages[messagesStartId]=true}  
          })
          setHasUnreadSellerMessages(tempHasUnreadSellerMessages)
          console.log("tempHasUnreadSellerMessages " + JSON.stringify(tempHasUnreadSellerMessages))

          console.log("rowId: "+rowId)
          let tempOpenSellerMessages = new Array(messagesDataSeller.length).fill(false);
          if (rowId >= 0) {
            tempOpenSellerMessages.map((tempOpenMessage, index) => {
              if (index<=rowId) {tempOpenSellerMessages[index]=openSellerMessages[index];}
              if (index===rowId+1) {tempOpenSellerMessages[index]=true;}
              if (index>(rowId+1)) {tempOpenSellerMessages[index+1]=openSellerMessages[index]}
            })
            console.log("rowId: "+rowId)
          } 
          setOpenSellerMessages(tempOpenSellerMessages);
          console.log("setOpenSellerMessages: "+JSON.stringify(tempOpenSellerMessages));
        })
        .catch(e => {
          console.log(e);
        });

      MessageDataService.getForBuyerByProfileId(currentProfile.id)         // Messages for Other Listings [BUYER View] (0 ... n conversations, 1 per Listing)
        .then(response => {
          let messagesDataBuyer = response.data
          setCurrentBuyerMessages(messagesDataBuyer)
          console.log("messagesDataBuyer" + JSON.stringify(messagesDataBuyer))

          let tempHasUnreadBuyerMessages = new Array(messagesDataBuyer.length).fill(false);
          let messagesStartId = 0 
          messagesDataBuyer.map((currentMessage, index) => { 
            if ((index>0) && ((currentMessage.listingId !== messagesDataBuyer[index-1].listingId) || (currentMessage.buyerProfileId !== messagesDataBuyer[index-1].buyerProfileId))) { messagesStartId=index}
            if ((currentMessage.wasRead==0) && (currentMessage.wasFromBuyer==0)) {tempHasUnreadBuyerMessages[messagesStartId]=true}  
          })
          setHasUnreadBuyerMessages(tempHasUnreadBuyerMessages)
          console.log("tempHasUnreadBuyerMessages " + JSON.stringify(tempHasUnreadBuyerMessages))

          console.log("rowId: "+rowId)
          let tempOpenBuyerMessages = new Array(messagesDataBuyer.length).fill(false);
          if (rowId >= 0) {
            tempOpenBuyerMessages.map((tempOpenMessage, index) => {
              if (index<=rowId) {tempOpenBuyerMessages[index]=openBuyerMessages[index];}
              if (index===rowId+1) {tempOpenBuyerMessages[index]=true;}
              if (index>(rowId+1)) {tempOpenBuyerMessages[index+1]=openBuyerMessages[index]}
            })
            console.log("rowId: "+rowId)
          } 
          setOpenBuyerMessages(tempOpenBuyerMessages);
          console.log("tempOpenBuyerMessages: "+JSON.stringify(tempOpenBuyerMessages));

      })
      .catch(e => {
        console.log(e);
      });
    }
  }

  const handleClickToggleSellerMessages = (buyerProfileId) => {
        // *** WHY IS LISTING-ID NOT AN INPUT TO THIS
    let tempOpenSellerMessages = [...openSellerMessages]
    currentSellerMessages.map((currentSellerMessage, index) => {

      if (currentSellerMessage.buyerProfileId===buyerProfileId) {
        tempOpenSellerMessages[index] = true
    
        if ((currentSellerMessage.wasRead == 0) && (((currentSellerMessage.buyerProfileId == currentProfile.id) && (currentSellerMessage.wasFromBuyer == 0)) || ((currentSellerMessage.buyerProfileId != currentProfile.id) && (currentSellerMessage.wasFromBuyer == 1)))) {
          MessageDataService.update(currentSellerMessage.id, {wasRead: 1}) 
          .then(response => {
            // setCurrent  ... state? (maybe like 3 secs later)
          })
          .catch(e => {
            console.log(e);
          });
        }
      }
    setOpenSellerMessages(tempOpenSellerMessages)
    })
  }

  const handleClickToggleBuyerMessages = (listingId) => {
    let tempOpenBuyerMessages = [...openBuyerMessages]
    currentBuyerMessages.map((currentBuyerMessage, index) => {

      if (currentBuyerMessage.listingId===listingId) {
        tempOpenBuyerMessages[index] = true
    
        if ((currentBuyerMessage.wasRead == 0) && (currentBuyerMessage.wasFromBuyer == 0)) {
          MessageDataService.update(currentBuyerMessage.id, {wasRead: 1}) 
          .then(response => {
            // setCurrent  ... state? (maybe like 3 secs later)
          })
          .catch(e => {
            console.log(e);
          });
        }
      }
    setOpenBuyerMessages(tempOpenBuyerMessages)
    })
  }

  return (
    <>
    { (currentProfile.id) && (
      <div className="container">
        { (currentSellerMessages) && (
          <div> 
            <h5>Messages for My Listings</h5>
            <div style={{'margin-top': `15px`}}>
              { currentSellerMessages.map((currentMessage, index) => (
                <>
                  {((index === 0) || ((index>0) && ((currentMessage.listingId !== currentSellerMessages[index-1].listingId) || (currentMessage.buyerProfileId !== currentSellerMessages[index-1].buyerProfileId)))) &&  (
                    <>
                      {(index > 0) && (<br/>)}
                      <span className="message-tab" onClick={() => handleClickToggleSellerMessages(currentMessage.buyerProfileId)}>
                        {(hasUnreadSellerMessages[index]===true) && (
                            <img width="20" height="20" alt='New Message(s)' src={envelope}  className={`message-icon`} />
                          )}
                          {currentMessage.firstName} <img width="20" height="20" alt='Show Message' src={angle_down} />
                        </span> 
                        <span> 
                          
                          <Link to={`/listing/${currentMessage.listingId}`} style={{'text-decoration': `none`, 'font-weight': `900`}}>&nbsp;{currentMessage.title}</Link></span><br/>
                    </>
                  )}

                  { (openSellerMessages[index]===true) &&  (
                    <>
                      <div key={index} className={ (currentMessage.wasFromBuyer == 1) ? `message-right` : `message-left`}>
                        { ((currentMessage.wasRead == 0) && (currentMessage.wasFromBuyer == 1)) && (
                          <img width="20" height="20" alt='New Message(s)' src={envelope} className={`message-icon`} />
                        )}
                        <span>{currentMessage.content}</span>
                        <span className="message-time"> {Utils.formatDate(currentMessage.updatedAt)}</span>
                        
                      </div>
                      { ((index==(currentSellerMessages.length-1))  || ( (index<(currentSellerMessages.length-1) ) && (currentMessage.buyerProfileId !== currentSellerMessages[index+1].buyerProfileId)) ) && (
                        <MessageForm listingId={currentMessage.listingId} buyerProfileId={currentMessage.buyerProfileId} wasFromBuyer={0} onSend={getMessages} rowId={index} />
                      )}
                    </>
                  ) }
                </>
              ) )}
            </div>
          </div>
        )}
        <br/>
        { (currentBuyerMessages) && (
          <div> 
            <h5>Messages for Other Listings</h5>
            <div style={{'margin-top': `15px`}}>
              { currentBuyerMessages.map((currentMessage, index) => (
                <>
                  {((index === 0) || ((index>0) && (currentMessage.listingId !== currentBuyerMessages[index-1].listingId))) &&  (
                    <>
                      {(index > 0) && (<br/>)}
                      <span className="message-tab" onClick={() => handleClickToggleBuyerMessages(currentMessage.listingId)}> 
                        {(hasUnreadBuyerMessages[index]===true) && (
                          <img width="20" height="20" alt='New Message(s)' src={envelope}  className={`message-icon`} />
                        )}
                        {currentMessage.firstName}
                        <img width="20" height="20" alt='Show Message' src={angle_down} />
                      </span> 
                      <span>&nbsp;<Link to={`/listing/${currentMessage.listingId}`} style={{'text-decoration': `none`, 'font-weight': `900`}}>{currentMessage.title}</Link></span><br/>
                    </>
                  )}

                  { (openBuyerMessages[index]===true) &&  (
                    <>
                      <div key={index} className={ (currentMessage.wasFromBuyer == 1) ? `message-left` : `message-right`}>
                        { ((currentMessage.wasRead == 0) && (currentMessage.wasFromBuyer == 0)) && (
                          <img width="20" height="20" alt='New Message(s)' src={envelope} className={`message-icon`} />
                        )}
                        <span>{currentMessage.content}</span>
                        <span className="message-time"> {Utils.formatDate(currentMessage.updatedAt)}</span>
                        <br/>
                      </div>
                      { ((index==(currentBuyerMessages.length-1))  || ( (index<(currentBuyerMessages.length-1) ) && (currentMessage.listingId !== currentBuyerMessages[index+1].listingId)) ) && (
                        <MessageForm listingId={currentMessage.listingId} buyerProfileId={currentMessage.buyerProfileId} wasFromBuyer={1} onSend={getMessages} rowId={index} />
                      )}
                    </>
                  )}
                </>
              ) )}
            </div>
          </div>
        )}
        
      </div>
    )}
    </>
  )
  
}

export default MyMessages;