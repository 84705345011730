import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import ListingDataService from "../../services/ListingService";
import FileService from "../../services/FileService"; 
import ProfileAuthService from "../../services/profile.auth.service";
import Utils from '../../services/utils';

function MyListings() {
  const [currentProfile, setCurrentProfile] = useState({});
  const [currentListings, setCurrentListings] = useState([]);
  
  const navigate = useNavigate();

  useEffect(() => {
    const profile = ProfileAuthService.getCurrentProfile();
    if (profile) {
      setCurrentProfile(profile);
    } else {
      navigate('/Login?refPage=%2FListing%2FMyListings')
    }  
  }, [])

  const getMyListings = () => {

    ListingDataService.getMyListings()
      .then(response => {
        setCurrentListings(response.data);
        console.log(response.data)
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getMyListings();
  }, []);

  return (
    <div>
      <h1>My Listings</h1>
      <div>
        { (currentListings && (currentListings.length>0)) ? (
          currentListings.map((currentListing, index) => (
            <div key={index} style={{'display': `flex`}}>
              { (currentListing.image1.length>0) && (
                  <div style={{'float': `left`, 'padding-right': `15px`}}>
                    <img alt="" src={`${FileService.uploadsPath}${currentListing.image1.replace('/', '/150_')}`}/>
                    <br/><br/>
                  </div>
              )}
              <div style={{'float': `right`, 'padding-bottom': `20px`}}>
              <h5><span><Link class="btn-link" to={`/listing/${currentListing.id}`} style={{'text-decoration': `none`, 'font-weight': `900`}}>{currentListing.title}&nbsp;{Utils.formatPrice(currentListing.price)}</Link></span></h5>
                <span>{currentListing.description}</span><br/>
                <br/>
                <span><b>Created On:</b> {Utils.formatDate(currentListing.createdAt)}</span><br/>
                <span><b>Updated Last:</b> {Utils.formatDate(currentListing.updatedAt)}</span><br/>

                
                {/* <Link to={`/listingForm/${currentListing.id}`}>Edit</Link> */}

                <span  style={{'padding-top': `20px`, 'padding-bottom': `20px`}}>
                  <a href={`/listingForm/${currentListing.id}`}>
                    <button className="btn btn-primary btn-block my-2" style={{'font-weight': `700`, 'width': `150px`}}>Edit Listing</button>
                  </a>
                </span>

              </div>
            </div>
        )) ) : (
            <div>You have no listings</div>
        )}
      </div>

    </div> 
  );
};

export default MyListings;
